/* global angular */
'use strict'

const moduleName = 'mapamagic.base.socketService'

angular.module(moduleName, [])
  .factory('socketService', ($localStorage, newSocket, actionService) => {
    'ngInject'
    newSocket.on('auth', (data) => {
      if (!$localStorage.userInfo) {
        actionService.logout()
      }
      if (data === 'success') {
        socket.authCallback()
      }
    })
    newSocket.on('geodata', data => {
      console.log('geodata', data)
      actionService.processingGeodataFromSocketService(data, socket.route, socket.type)
    })
    newSocket.on('stops-time', data => actionService.processingStopsDataFromSocketService(data, socket.subscribeData.routes, socket.type))

    let socket = {
      route: [],
      type: 'bus',
      subscribeData: {},
      authCallback () {},
      auth (data, callback) {
        socket.authCallback = callback
        newSocket.emit('user-auth', data)
      },
      geodata (data) {
        newSocket.emit('subscribe', {'type': 'geodata', 'subject': data})
      },
      subscribe (data) {
        newSocket.emit('subscribe', {'type': 'stops-time', 'subject': data})
      }
    }
    return socket
  })

export default moduleName
